
import {
  Emit,
  Prop,
  Component,
  Mixins,
  Watch,
  InjectReactive
} from 'vue-property-decorator';
import Vue from 'vue';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import ProfileDetailsBaseComponent from './ProfileDetailsBaseComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { getSectionStatus } from '@/util/getSectionStatus';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import UpdateStickyComponent from '@/components/UpdateStickyComponent.vue';

const sections = convertToKeyValueObj({
  PRE_IN_PROCESSING: 'Pre Inpro',
  IN_PROCESSING: 'Inpro',
  POST_IN_PROCESSING: 'Post Inpro'
});

class Model extends BaseCandidate {
  statusFunction: (fields: unknown[]) => ESectionStatus;

  get preInProSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.inProcessingInproccessingstartdate,
      this.inProcessingOfficertrainingschoolstartdate,
      this.inProcessingGtcsupervisordate,
      this.inProcessingGtcapcdate,
      this.inProcessingGtcactivationdate,
      this.inProcessingGtccsoudate,
      this.inProcessingGtccbtdate,
      this.inProcessingOrderdate,
      this.inProcessingOutlookdistrodate,
      this.inProcessingFmpackagedate,
      this.inProcessingUniformlettersdate,
      this.inProcessingFundingdate,
      this.inProcessingShotrecorddate,
      this.inProcessingConfirmeftdate,
      this.inProcessingMmpadate,
      this.inProcessingUniformlettersdate,
      this.inProcessingAlterationsdate
    ]);
  }

  get inProSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.inProcessingDtsaccountdate,
      this.inProcessingRscdate,
      this.inProcessingAf2583date,
      this.inProcessingMillconnectdate,
      this.inProcessingEducationtransdate,
      this.inProcessingNatobriefdate,
      this.inProcessingCertifiedinprocessingdate,
      this.inProcessingOrdermodrouteddate,
      this.inProcessingOfficertrainingschoolorderdate,
      this.inProcessingInproccesingvoucherdate,
      this.inProcessingRouteorderdate,
      this.inProcessingMylearningtranscriptdate,
      this.inProcessingPoadate,
      this.inProcessingMovemilitarybasedate,
      this.inProcessingSf86date,
      this.inProcessingDissreviewdate,
      this.inProcessingNdadate,
      this.inProcessingFingerprintsdate
    ]);
  }

  get postInProSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.inProcessingInprocessingcompleteddate,
      this.inProcessingSglidate
    ]);
  }

  constructor(
    partial?: Partial<BaseCandidate>,
    statusFunction = getSectionStatus
  ) {
    super(partial);
    this.statusFunction = statusFunction;
  }
}

@Component<InProcessingPage>({
  components: {
    DateSelectorComponent,
    ProfileDetailsBaseComponent,
    UpdateStickyComponent
  }
})
export default class InProcessingPage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  applicant!: Applicant;

  @InjectReactive('isUpdating')
  isUpdating!: boolean;

  model = new Model();
  created(): void {
    this.model = new Model(this.applicant);
  }

  yesNoOptions = yesNoOptions;
  sections = sections;

  @Watch('model')
  @Watch('applicant')
  setSectionStatus(): void {
    const { PRE_IN_PROCESSING, IN_PROCESSING, POST_IN_PROCESSING } =
      this.sections;

    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'In-Processing',
        entries: [
          {
            text: PRE_IN_PROCESSING.label,
            status: this.model.preInProSectionStatus,
            onClick: generateScrollToCallback(PRE_IN_PROCESSING.key)
          },
          {
            text: IN_PROCESSING.label,
            status: this.model.inProSectionStatus,
            onClick: generateScrollToCallback(IN_PROCESSING.key)
          },
          {
            text: POST_IN_PROCESSING.label,
            status: this.model.postInProSectionStatus,
            onClick: generateScrollToCallback(POST_IN_PROCESSING.key)
          }
        ]
      }
    ]);
  }

  @Emit('updateApplicant')
  updateApplicant(): Model {
    return this.model;
  }
}
